import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Plan as PlanViewer} from '@wix/seating-viewer'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {MODAL_BUTTONS} from '@wix/wix-events-commons-statics'
import {OnPlaceMouseEvent} from '@wix/seating-common-components'
import {MAX_ZOOM, useViewBox} from '../../../../hooks/viewbox'
import {PopoverData, SeatingPopover} from '../seating-popover'
import {getPlaceInfo, calculatePlacesStock} from '../../../../selectors/seating'
import {Controls} from './controls'
import {Legend} from './legend'
import s from './plan.scss'
import {PlanProps} from './index'

const ZOOM_STEP = 0.2

export const Plan = ({
  plan,
  placesInBasket,
  onPlaceClick,
  placesWithTicketInfo,
  selectedPlace,
  showAccessibilityMode,
  forcedPopover,
  setAccessibilityToast,
  seatingMapButtonClick,
}: PlanProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const container = React.useRef<HTMLDivElement>(null)
  const svgContainer = React.useRef<HTMLDivElement>(null)

  const {
    ready,
    viewBox,
    zoom,
    cursor,
    changeSvgViewBox,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onPointerLeave,
    minZoom,
  } = useViewBox(plan, svgContainer)

  const [popoverData, setPopoverData] = useState(null as PopoverData)
  const placesStock = useMemo(() => calculatePlacesStock(placesWithTicketInfo), [placesWithTicketInfo])

  useEffect(() => {
    if (forcedPopover) {
      const info = getPlaceInfo(placesWithTicketInfo, forcedPopover)
      const boundingClientRect = document.getElementById(forcedPopover)?.getBoundingClientRect()
      const containerBoundingClientRect = container.current.getBoundingClientRect()

      if (!info || !boundingClientRect) {
        return undefined
      }

      setPopoverData({boundingClientRect, info, containerBoundingClientRect} as PopoverData)
    } else {
      setPopoverData(null)
    }
  }, [forcedPopover])

  const onPlaceMouseEnter: OnPlaceMouseEvent = useCallback(
    ({place, event, places}) => {
      const info = getPlaceInfo(placesWithTicketInfo, place?.id ?? places[0].id)

      if (!info) {
        return null
      }

      const containerBoundingClientRect = container.current.getBoundingClientRect()
      const boundingClientRect = event.currentTarget.getBoundingClientRect()

      setPopoverData({boundingClientRect, info, containerBoundingClientRect} as PopoverData)
    },
    [placesWithTicketInfo],
  )

  const onPlaceMouseLeave: OnPlaceMouseEvent = useCallback(() => {
    setPopoverData(null)
  }, [])

  const selectedPlacesIds = useMemo(() => placesInBasket.map(place => place.id), [placesInBasket])

  const handleZoomIn = () => {
    seatingMapButtonClick(MODAL_BUTTONS.ZOOM_IN)
    changeSvgViewBox({deltaScale: ZOOM_STEP})
  }

  const handleZoomOut = () => {
    seatingMapButtonClick(MODAL_BUTTONS.ZOOM_OUT)
    changeSvgViewBox({deltaScale: -ZOOM_STEP})
  }

  return (
    <div
      ref={container}
      className={classNames(s.container, {[s.mobileContainer]: isMobile})}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerLeave={onPointerLeave}
      onPointerMove={onPointerMove}
      style={{cursor}}
    >
      {showAccessibilityMode ? (
        <div
          className={s.overlay}
          data-hook={DH.ACCESSIBILITY_MAP_OVERLAY}
          onClick={() => setAccessibilityToast(true)}
        />
      ) : null}
      <div aria-hidden="true" ref={svgContainer} className={s.svgContainer}>
        {ready ? (
          <PlanViewer
            onPlaceMouseLeave={isMobile || showAccessibilityMode ? null : onPlaceMouseLeave}
            onPlaceMouseEnter={isMobile || showAccessibilityMode ? null : onPlaceMouseEnter}
            placesStock={placesStock}
            currentlyClickedPlaceId={selectedPlace?.id}
            selectedPlaceIds={selectedPlacesIds}
            plan={plan}
            svgViewBox={viewBox}
            onPlaceClick={showAccessibilityMode ? undefined : onPlaceClick}
            mobile={isMobile}
          />
        ) : null}
      </div>
      {isMobile ? null : <Legend t={t} />}
      <Controls
        zoomOutDisabled={zoom === minZoom}
        zoomInDisabled={zoom === MAX_ZOOM}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
      />
      {popoverData ? <SeatingPopover popoverData={popoverData} /> : null}
    </div>
  )
}
