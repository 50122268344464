import React from 'react'
import {IconButton} from 'wix-ui-tpa/IconButton'
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import SelectFill from 'wix-ui-icons-common/on-stage/SelectFill'
import classNames from 'classnames'
import {Divider} from 'wix-ui-tpa/Divider'
import {Text} from 'wix-ui-tpa/Text'
import {useColors} from '../../../../../hooks/colors'
import {getPlaceInfoText} from '../../../../../selectors/seating'
import {getTicketPriceText} from '../../../../../selectors/order-success'
import {classes} from './ticket-info.st.css'
import {TicketInfoTexts} from './ticket-info-texts'
import {CustomDatesLabel} from './custom-dates-label'
import {TicketInfoProps} from './index'

export const TicketInfo = ({
  place,
  showAccessibilityMode,
  onAddClick,
  onCloseClick,
  onRemoveClick,
  inPopover,
  basketView,
  inDescription,
}: TicketInfoProps) => {
  const {t} = useTranslation()
  const {darkColor} = useColors()
  const showCustomDatesLabel = onAddClick || showAccessibilityMode || inPopover
  const {inBasket, selected, places, ticket} = place
  const showCheckbox = inBasket && !selected && !basketView && !inPopover && !inDescription
  const wholeElementReservation = Boolean(place.reservationOptions?.reserveWholeElement)

  return (
    <div className={classes.container}>
      {showCheckbox ? (
        <SelectFill
          className={classNames(classes.checkbox, {
            [classes.checkboxFillWired]: darkColor,
            [classes.checkboxFillNotWired]: !darkColor,
          })}
        />
      ) : null}
      {onCloseClick ? (
        <IconButton className={classes.headerButton} icon={<CloseSmall />} onClick={onCloseClick} />
      ) : null}
      {onRemoveClick ? (
        <IconButton
          data-hook={DH.REMOVE_TICKET}
          className={classes.headerButton}
          icon={<DeleteSmall />}
          onClick={onRemoveClick}
          aria-label={`${t('seatings_removeFromBasket')} ${getPlaceInfoText(place, t)}`}
        />
      ) : null}
      {showCustomDatesLabel && <CustomDatesLabel place={place} />}
      <TicketInfoTexts place={place} inPopover={inPopover} inDescription={inDescription} />
      {wholeElementReservation ? (
        <div>
          <Divider className={classes.divider} />
          <Text className={classes.tableReservation}>
            {t('seating_tableReservation', {
              price: getTicketPriceText(
                {
                  amount: String(Number(ticket.price.amount) * places.length),
                  currency: ticket.price.currency,
                },
                t,
              ),
            })}
          </Text>
        </div>
      ) : null}
    </div>
  )
}
